import { gql } from '@apollo/client'
import MessageFragment from '../fragments/message'

export default gql`
    query Message($id: Int!) {
        messages(where: {message_id: {_eq: $id}}) {
            ...Message
        }
    }
    ${MessageFragment}
`