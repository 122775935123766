import { gql } from '@apollo/client'
import MessageFragment from '../fragments/message'

export default gql`
    query Messages($chatId: bigint, $limit: Int, $offsetMessageId: Int) {
        messages(
            order_by: {
                message_id: desc
            },
            where: {
                user_id: {
                    _eq: $chatId
                },
                message_id: {
                    _lt: $offsetMessageId
                    _is_null: false
                }
            },
            limit: $limit
        ) {
            ...Message
        }
    }
    ${MessageFragment}
`
