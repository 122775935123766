import { gql } from '@apollo/client'
import userChatFragment from '../fragments/chat'

export default gql`
    query Chat($id: bigint!) {
        users(where: {user_id: {_eq: $id}}) {
            ...UserChat
        }
    }
    ${userChatFragment}
`
