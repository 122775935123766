import { gql } from '@apollo/client'
import userChatFragment from '../fragments/chat'

export default gql`
    query ChatsByTag($offsetMessageId: Int, $limit: Int, $tagId: Int) {
        users(
            where:{
                last_message_id: {
                    _lt: $offsetMessageId,
                    _is_null: false
                }
                tags: {
                    tag_id: {
                        _eq: $tagId
                    }
                }
                banned_us: {
                    _eq: false
                }
                banya: {
                    _eq: false
                }
            },
            order_by: {
                last_message_id: desc_nulls_last},
                limit: $limit
            )
        {
            ...UserChat
        }
    }
    ${userChatFragment}
`
