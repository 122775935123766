import { gql } from '@apollo/client'
import userChatFragment from '../fragments/chat'

export default gql`
    query Chats($offsetMessageId: Int, $limit: Int) {
        users(
            where:{
                last_message_id: {
                    _lt: $offsetMessageId,
                    _is_null: false
                }
                banned_us: {
                    _eq: false
                }
                banya: {
                    _eq: false
                }
            }
            order_by: {
                last_message_id: desc
            }
            limit: $limit
        )
        {
            ...UserChat
        }
    }
    ${userChatFragment}
`
