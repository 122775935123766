import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    fromPromise,
    from
} from '@apollo/client'
import { RetryLink } from "@apollo/client/link/retry"
import { onError } from '@apollo/client/link/error'
import promiseToObservable from './promiseToObservable'
import { setContext } from '@apollo/client/link/context'
import OiBotClient, { AUTH_STORAGE_KEY } from '../OiBotClient'

const { REACT_APP_GRAPHQL_ENDPOINT } = process.env
if (!REACT_APP_GRAPHQL_ENDPOINT) {
    throw new Error('REACT_APP_GRAPHQL_ENDPOINT required')
}

const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem(AUTH_STORAGE_KEY)
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
})

let reAuthenticating: Promise<string | void>

const reAuthenticate = async () => {
    if (!reAuthenticating) {
        reAuthenticating = OiBotClient.oiAuthenticate()
    }

    const token = await reAuthenticating

    return token
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            console.error(err)
            switch (err?.extensions?.code) {
                case 'access-denied':
                    console.warn('re-authenticating')

                    return promiseToObservable(reAuthenticate()).flatMap((token) => {
                        const oldHeaders = operation.getContext().headers
                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                authorization: token ? `Bearer ${token}` : "",
                            },
                        })
                        return forward(operation)
                    })
            }
        }
    }
})

const client = new ApolloClient({
    link: from([
        errorLink,
        new RetryLink(),
        authLink,
        httpLink,
    ]),
    cache: new InMemoryCache({
        typePolicies: {
            user_tag_relations: { keyFields: ['user_id', 'tag_id'] }
        }
    })
})

OiBotClient.oiClient = client

export default client