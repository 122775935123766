import { gql } from '@apollo/client'

export default gql`
    fragment UserChat on users {
        first_name
        created
        last_name
        phone_number
        last_message_id
        to_reply_message_id
        user_id
        username
        unread
        banned_us
        read_outbox_max_id
        read_inbox_max_id
        operators {
            operator_id
        }
        tags {
            tag_id
            user_id
        }
    }
`