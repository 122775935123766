import TdClient, { TdOptions, TdObject as NativeObject } from 'tdweb';
import { ApolloClient } from '@apollo/client'
import EventEmitter from './Stores/EventEmitter'
import * as Api from '@airgram/core/types/api'
import * as MessageContent from '@airgram/core/types/outputs/MessageContent'
import { Airgram, toObject, UpdateContext, BaseTdObject, ApiResponse } from '@airgram/web'
import { Message as BotMessage } from 'telegram-typings'
import { createBrowserHistory } from 'history'

import CHATS_QUERY from './Graphql/queries/chats'
import CHATS_BY_OPERATOR_QUERY from './Graphql/queries/chatsByOperator'
import CHATS_BY_TAG_QUERY from './Graphql/queries/chatsByTag'
import CHATS_NO_TAG_QUERY from './Graphql/queries/chatsNoTag'
import CHATS_PENDING_QUERY from './Graphql/queries/chatsPending'
import CHAT_QUERY from './Graphql/queries/chat'
import MESSAGE_QUERY from './Graphql/queries/message'
import MESSAGES_QUERY from './Graphql/queries/messages'
import TAGS_QUERY from './Graphql/queries/tags'
import {
    UserChatFragment,
    ChatsQuery,
    ChatsQueryVariables,
    ChatQuery,
    ChatQueryVariables,
    ChatsByOperatorQuery,
    ChatsByOperatorQueryVariables,
    ChatsPendingQuery,
    ChatsPendingQueryVariables,
    ChatsByTagQuery,
    ChatsByTagQueryVariables,
    ChatsNoTagQuery,
    ChatsNoTagQueryVariables,
    MessageFragment,
    MessageQuery,
    MessageQueryVariables,
    MessagesQuery,
    MessagesQueryVariables,
    TagsQuery
} from './Graphql/schema'

import { TdLibController } from './Controllers/TdLibController'
// TODO: chat order green top
// TODO: chat positions multiple lists

interface OiMessageFragment extends MessageFragment {
    mess: BotMessage
}

const history = createBrowserHistory()
/*
    В качестве обертки над TdLib/TdWeb будем использовать Airgram
    Там все методы типизированы и используют удобные врапперы
*/

const ORDER = '9223372036854775807'

const AUTH_STORAGE_KEY = 'oiapitoken'
const USER_ID_STORAGE_KEY = 'oiuserid'

const idRegex = /\<(\d+)\>/gm

const parseId = (text?: string): number | undefined => {

    if (!text) return
    idRegex.lastIndex = 0
    const result = idRegex.exec(text)

    if (!result) return

    if (result.length === 2) {
        return parseInt(result[1])
    }
}

let queryId = 0


// я не знаю почему, но у телеграма два вида айдишников
// длинный и короткий, отличаются в это число раз
const MAGIC_NUMBER = 1048576
interface OiChat {
    created: number
    first_name: string
    last_name: string
    mess: BotMessage
    message_id: number
    mid: string
    phone_number: string
    read_inbox_max_id: number
    read_outbox_max_id: number
    to_reply_message_id: number
    user_id: number
    username: string
}

interface OiMessageResponse {
    mess: string
    user_id: number
    created: number
    oi_user_id: number
    message_id: number
}

interface OiChatResponse {
    response: OiChat
}

function getObject<T extends BaseTdObject>({ response }: ApiResponse<any, T>): T | undefined {
    if (response?._ !== 'error') return response as T
}

function wait(ms: number): Promise<void> {
    return new Promise(res => {
        setTimeout(res, ms)
    })
}

const FILTER_MY = -1;
const FILTER_UNREAD = -2;
const FILTER_NOTAG = -3;

(BigInt.prototype as any).toJSON = function () {
    return this.toString();
};

class OiBotClient {
    //@ts-ignore
    private client: Airgram
    //@ts-ignore
    public oiClient: ApolloClient<unknown>
    private myId?: number
    private botId = Number(process.env.REACT_APP_BOT_ID);
    private chatId = Number(process.env.REACT_APP_CHAT_ID);
    private chatName = process.env.REACT_APP_CHAT_NAME;
    private botNick = process.env.REACT_APP_BOT_NICK;
    private currentTdlibChatId = 0
    private currentFilter: number | undefined
    private visibleChats = new Set<number>()
    private pendingSendRequests: Promise<ApiResponse<Api.SendMessageParams, Api.Message>>[] = []
    private events = new EventEmitter()
    private apiUrl = 'https://botapi.ovdinfo.org/oibotapi/'
    private chatScope = new Map<number, UserChatFragment>()
    private userScope = new Map<number, Api.User>()
    //@ts-ignore
    private controller: TdLibController
    public onUpdate?: (update: NativeObject) => any
    private offsetChatId = 0

    init(options: TdOptions, controller: TdLibController) {
        // localStorage.clear()
        this.client = new Airgram(options)
        this.controller = controller

        // не храним кэш
        localStorage.removeItem('cache')

        if (sessionStorage.getItem(USER_ID_STORAGE_KEY)) {
            this.myId = parseInt(sessionStorage.getItem(USER_ID_STORAGE_KEY) || '')
        }

        //Кидаем каждое событие во внутренний eventEmitter
        this.client.use((ctx, next) => {
            this.events.emit(ctx._, ctx)
            return next()
        })

        this.client.on('updateAuthorizationState', async ({ update }, next) => {
            // Событие authorizationStateReady означает, что клиент готов к работе
            // Задерживаем отправку этого события клиенту и авторизуемся в ОВД-Инфо
            switch (update.authorizationState._) {
                case 'authorizationStateReady': {
                    console.log('READY')
                    this.oiUpdateUserId()

                    const storedToken = localStorage.getItem(AUTH_STORAGE_KEY) || ''

                    // авторизуемся при запуске только, если нет сохраненных данных
                    if (storedToken.length !== 40 || !this.myId) {
                        await this.oiAuthenticate()
                    } else {
                        console.log('Got OI token and id from session storage')
                    }

                    // открываем чат, если он передан в url
                    const queryString = window.location.search
                    const urlParams = new URLSearchParams(queryString)
                    const idUrlParam = urlParams.get('p')
                    if (idUrlParam) {
                        const chatId = parseInt(idUrlParam)
                        if (chatId) {
                            await this.oiOpenSupportChat()
                            this.oiClientOpenChat(chatId)
                        }
                    }

                    // отправляем клиенту список фильтров
                    const updateChatFilters: Api.UpdateChatFilters = {
                        _: 'updateChatFilters',
                        chatFilters: [
                            this.oiGetChatFilterInfo(FILTER_NOTAG, 'Без метки'),
                            this.oiGetChatFilterInfo(FILTER_MY, 'Мои'),
                            this.oiGetChatFilterInfo(FILTER_UNREAD, 'Ждут')
                        ]
                    }
                    this.oiSendUpdate(updateChatFilters)

                    this.oiClient.query<TagsQuery>({
                        query: TAGS_QUERY
                    }).then(({ data }) => {
                        data.tags.forEach(tag =>
                            updateChatFilters.chatFilters.push(this.oiGetChatFilterInfo(tag.tag_id, tag.tag_name || ''))
                        )
                        this.oiSendUpdate(updateChatFilters)
                    })

                    break
                }
                case 'authorizationStateClosed': {
                    sessionStorage.removeItem(AUTH_STORAGE_KEY)
                    sessionStorage.removeItem(USER_ID_STORAGE_KEY)
                    break
                }
            }
            next()
        })
        this.client.on('updateNewMessage', async ({ update }, next) => {
            // пропускаем в клиент только сообщения из чата поддержки
            if (update.message.chatId === this.chatId) {
                if (this.pendingSendRequests.length > 0) {
                    // если есть сообщения, которые отправляются в данный момент, ждём их отправки
                    // подробности в методе oiSendMessage
                    const results = await Promise.all(this.pendingSendRequests)
                    this.pendingSendRequests.length = 0

                    // получам id отправленных сообщений
                    const messageIdsToSkip = results.map(r => {
                        if (r.response._ === 'message') {
                            return r.response.id
                        }
                    })

                    // если это только что отправленное сообщение, игнорим его, оно будет обработано из oiSendMessage
                    if (messageIdsToSkip.includes(update.message.id)) {
                        return
                    }
                }

                await this.oiHandleNewMessage(update.message)
            }
        })
        this.client.on('updateUser', async ({ update }, next) => {
            const user = update.user
            if (!this.userScope.has(user.id)) {
                // const oiUser = await this.oiGetChat(user.id)
                // user.phoneNumber = oiUser?.phone_number || user.phoneNumber
                this.userScope.set(user.id, user)
                this.oiSendUpdate(update)
            }
        })
        this.client.use((ctx, next) => {
            //Если событие это update, передаем его клиенту
            if ('update' in ctx) {
                let passed = false
                //@ts-ignore
                switch (ctx.update._) {
                    case 'updateOption':
                    case 'updateSelectedBackground':
                    case 'updateDiceEmojis':
                    case 'updateScopeNotificationSettings':
                    case 'updateConnectionState':
                    case 'updateAnimationSearchParameters':
                    // case 'updateHavePendingNotifications':
                    case 'updateInstalledStickerSets':
                    case 'updateRecentStickers':
                    case 'updateSavedAnimations':
                    case 'updateFavoriteStickers':
                    case 'updateTrendingStickerSets':
                    case 'updateUserStatus':
                    case 'ok':
                    case 'updateFile':
                    case 'updateAuthorizationState': {
                        if (this.onUpdate) {
                            // console.log('passed update', ctx.update._)
                            passed = true

                            //@ts-ignore
                            this.onUpdate(this.client.provider.serialize(ctx.update))
                        }
                        break
                    }
                    case 'updateChatFilters': {
                        break
                    }
                    // список обновлений, которые мы игнорируем полностью
                    // обновления, которые мы обрабатываем, находятся выше
                    default: {
                        // console.log('skipped update', ctx.update._)
                    }
                }
                // console.log(passed ? '✅' : '🛑', ctx.update._)
            } else {
                return next()
            }
        })

        return this
    }

    /**
     * отправляет апдейт напрямую в клиент
     */
    oiSendUpdate(update: any) {
        if (this.onUpdate) {
            // console.log('sending update directly', update._, update)
            // console.log('🚧', update._, update?.chat?.id || update?.chatId || update)
            //@ts-ignore
            this.onUpdate(this.client.provider.serialize(update))
        }
    }

    /**
     * место, где обрабатываются запросы от юзера
     */
    async send(request: NativeObject) {
        // console.log('⚛️', queryId, request["@type"], request)
        queryId += 1

        switch (request['@type']) {
            case 'openMessageContent':
            case 'sendChatAction':
            case 'setChatDraftMessage':
            case 'getGroupsInCommon':
            case 'viewMessages': {
                return
            }
            case 'getChatFilterDefaultIconName': return
            case 'getChatFilter': {
                console.log('FILTER', request["@type"])
                return
            }
            case 'createPrivateChat': {
                //@ts-ignore
                const typedRequest: Api.CreatePrivateChatParams = this.client.provider.deserialize(request)

                if (typedRequest.userId === this.myId) {
                    // пропускаем запрос на создание чата с самим собой для избежания ошибок,
                    // если он будет undefined
                    break
                }
                return
            }
            case 'createBasicGroupChat': {
                return
            }
            case 'getBasicGroupFullInfo': {
                //@ts-ignore
                const typedRequest: Api.GetBasicGroupFullInfoParams = this.client.provider.deserialize(request)

                const groupFullInfo: Api.BasicGroupFullInfo = {
                    _: 'basicGroupFullInfo',
                    members: [],
                    // members: [],
                    description: '',
                    creatorUserId: typedRequest.basicGroupId || 0,
                    inviteLink: undefined,
                    botCommands: []
                }

                const chat = this.chatScope.get(typedRequest.basicGroupId || 0)

                if (chat) {
                    groupFullInfo.members = chat.operators.map(op => ({
                        _: 'chatMember',
                        memberId: {
                            _: 'messageSenderUser',
                            userId: Number(op.operator_id) || 0,
                        },
                        inviterUserId: 0,
                        joinedChatDate: 0,
                        status: {
                            _: 'chatMemberStatusMember',
                        }
                    }))
                }

                //@ts-ignore
                return this.client.provider.serialize(groupFullInfo)
            }
            case 'getChat': {
                //@ts-ignore
                const typedRequest: Api.GetChatParams = this.client.provider.deserialize(request)

                const result = this.oiCreateChat(typedRequest.chatId || 0)
                if (typedRequest.chatId === this.myId) {
                    result.type._ = 'chatTypePrivate'
                }
                //@ts-ignore
                return this.client.provider.serialize(result)
            }
            case 'getChats': {
                //@ts-ignore
                const typedRequest: Api.GetChatsParams = this.client.provider.deserialize(request)

                // getChats может вызываться не только для отображения сообщений, но и для кеширования
                const result = await this.oiHandleGetChats(typedRequest)
                //@ts-ignore
                return this.client.provider.serialize(result)
            }
            case 'getChatHistory': {
                //@ts-ignore
                const typedRequest: Api.GetChatHistoryParams = this.client.provider.deserialize(request)
                //@ts-ignore
                return this.client.provider.serialize(await this.oiHandleGetChatHistory(typedRequest))
            }
            case 'getMessage': {
                // TODO: отдать ему сообщение, которые он просит
                return
                // return {
                //     id: -1,
                //     chat_id: -1
                // }
            }
            case 'getMessages': {
                //@ts-ignore
                const typedRequest: Api.GetMessagesParams = this.client.provider.deserialize(request)

                // TODO: отдать ему сообщения, которые он просит
                const result: Api.Messages = {
                    _: 'messages',
                    //@ts-ignore
                    messages: typedRequest.messageIds?.map(m => undefined) || [],
                    totalCount: typedRequest.messageIds?.length || 0
                }
                //@ts-ignore
                return this.client.provider.serialize(result)
            }
            case 'sendMessage': {
                //@ts-ignore
                const typedRequest: Api.SendMessageParams = this.client.provider.deserialize(request)
                //@ts-ignore
                return this.client.provider.serialize(await this.oiSendMessage(typedRequest))
            }
            case 'closeChat': {
                return
            }
            case 'openChat': {
                //Это то место, где мы узнаём о том, что юзер открывает чат
                //@ts-ignore
                const typedRequest: Api.OpenChatParams = this.client.provider.deserialize(request)
                history.push(`/?p=${typedRequest.chatId}`)
                window.parent.postMessage({ "chatId": typedRequest.chatId }, "*");

                const a: Api.Ok = {
                    _: 'ok'
                }
                return a
            }
            case 'createPrivateChat': {
                //@ts-ignore
                const typedRequest: Api.CreatePrivateChatParams = this.client.provider.deserialize(request)
                //@ts-ignore
                return this.client.provider.serialize(this.oiCreateChat(typedRequest.userId || 0))
            }
            case 'searchChatMessages': {
                // Заглушка.
                // Этот метод вызывается автоматически, когда открываешь картинку на клиенте
                const result: Api.Messages = {
                    _: 'messages',
                    messages: [],
                    totalCount: 0
                }
                //@ts-ignore
                return this.client.provider.serialize(result)
            }
            case 'getUserFullInfo':
                //@ts-ignore
                const typedRequest: Api.GetUserFullInfoParams = this.client.provider.deserialize(request)

                return await this.client.api.getUserFullInfo(typedRequest)
            case 'getUser': {
                return
            }
        }

        //@ts-ignore
        return this.client.provider.client.send(request)
    }

    oiWaitForTokenMessage() {
        let handleBotMessage: unknown

        return new Promise<string>((resolve, reject) => {
            handleBotMessage = ({ update }: UpdateContext<Api.UpdateNewMessage>) => {
                if (update.message.senderId._ === 'messageSenderUser') {
                    if (update.message.senderId.userId === this.botId && update.message.chatId === this.botId) {
                        const content = update.message.content as MessageContent.MessageText
                        const text = content.text.text
                        if (text.indexOf('accesstoken:') === 0) {
                            const token = text.substr(12)
                            resolve(token)
                        }
                        let error = text

                        if (error.indexOf('error:') === 0) {
                            error = error.substr(6)
                            if (error.includes('Нет в конфе')) {
                                error = `Вас нет в чате ${this.chatName}`
                            } else if (error.includes('Не найден юзер')) {
                                error = `Бот вас не знает. Откройте бота ${this.botNick} в Telegram, и нажмите /start`
                            }
                        }
                        reject(error)
                    }
                }
            }

            // слушаем сообщения от бота
            this.events.on('updateNewMessage', handleBotMessage)
        }).finally(() => {
            // отключаем обработчик сообщений
            this.events.off('updateNewMessage', handleBotMessage)
        })
    }

    async oiGetAuthTokenFromBot(): Promise<string | void> {
        console.log('Requesting new token from OI bot')

        const searchResult = toObject(await this.client.api.searchChatsOnServer({
            query: this.botNick
        }))

        // Отправляем боту команду для получения токена
        const sendResult = toObject(await this.client.api.sendMessage({
            chatId: this.botId,
            inputMessageContent: {
                _: 'inputMessageText',
                text: {
                    _: 'formattedText',
                    text: '/admintoken'
                }
            }
        }))

        const token = await Promise.race([
            this.oiWaitForTokenMessage(),
            wait(5000)
        ]).catch(e => {
            console.log('AUTH ERROR')
            alert('Ошибка авторизации: ' + e)
        })

        // Удаляем сообщение с командой
        await this.client.api.deleteMessages({
            chatId: this.botId,
            messageIds: [sendResult.id],
            revoke: true
        })

        if (token && token.length === 40) {
            return token
        }
    }

    async oiUpdateUserId() {
        const me = toObject(await this.client.api.getMe())
        this.myId = me.id
        sessionStorage.setItem(USER_ID_STORAGE_KEY, me.id.toString())
        return me.id
    }

    async oiAuthenticate() {
        console.log('Starting OI auth process')

        await this.oiUpdateUserId()

        const token = await this.oiGetAuthTokenFromBot()
        if (token && token.length === 40) {
            // сохраняем токен
            sessionStorage.setItem(AUTH_STORAGE_KEY, token)
            console.log('Auth complete')
        } else {
            throw new Error('oi bot auth failed')
        }

        return token
    }

    /**
     * Проверяет существует ли чат в клиенте
     */
    oiClientHasChat(chatId: number): boolean {
        return this.chatScope.has(chatId)
    }

    /**
     * Получить сообщение по id от API овд-инфо
     * @param короткий messageId
     */
    async oiGetMessage(messageId?: number): Promise<MessageFragment | undefined> {
        if (!messageId) return
        const result = await this.oiClient.query<MessageQuery, MessageQueryVariables>({
            query: MESSAGE_QUERY,
            variables: {
                id: messageId
            },
            fetchPolicy: 'network-only'
        })

        return result.data.messages[0]
    }

    /**
     * Получить сообщение по id из админского чата от API telegram
     * @param короткий messageId
     */
    async oiTgGetMessage(messageId: number): Promise<Api.Message | undefined> {
        const data = await this.client.api.getMessages({
            chatId: this.chatId,
            messageIds: [messageId * MAGIC_NUMBER]
        })
        if (data.response._ === 'messages') {
            return data.response.messages?.[0]
        }
    }

    async oiGetChats(offsetChatId = 0, limit = 50, filter?: number) {
        let offsetMessageId = 0
        if (offsetChatId) {
            const chat = await this.oiGetChat(offsetChatId)
            if (!chat) return []

            this.chatScope.set(Number(chat.user_id), chat)
            offsetMessageId = chat?.last_message_id || 0
        }

        if (!filter) {
            const result = await this.oiClient.query<ChatsQuery, ChatsQueryVariables>({
                query: CHATS_QUERY,
                variables: {
                    offsetMessageId: offsetMessageId || null,
                    limit
                },
                fetchPolicy: 'network-only'
            })

            return result.data.users || []
        }

        switch (filter) {
            case FILTER_MY: {
                const result = await this.oiClient.query<ChatsByOperatorQuery, ChatsByOperatorQueryVariables>({
                    query: CHATS_BY_OPERATOR_QUERY,
                    variables: {
                        offsetMessageId: offsetMessageId || null,
                        limit,
                        operatorId: BigInt(this.myId)
                    },
                    fetchPolicy: 'network-only'
                })

                return result.data.users || []
            }
            case FILTER_UNREAD: {
                const result = await this.oiClient.query<ChatsPendingQuery, ChatsPendingQueryVariables>({
                    query: CHATS_PENDING_QUERY,
                    variables: {
                        offsetMessageId: offsetMessageId || null,
                        limit
                    },
                    fetchPolicy: 'network-only'
                })

                return result.data.users || []
            }
            case FILTER_NOTAG: {
                const result = await this.oiClient.query<ChatsNoTagQuery, ChatsNoTagQueryVariables>({
                    query: CHATS_NO_TAG_QUERY,
                    variables: {
                        offsetMessageId: offsetMessageId || null,
                        limit
                    },
                    fetchPolicy: 'network-only'
                })

                return result.data.users || []
            }
            default: {
                const result = await this.oiClient.query<ChatsByTagQuery, ChatsByTagQueryVariables>({
                    query: CHATS_BY_TAG_QUERY,
                    variables: {
                        offsetMessageId: offsetMessageId || null,
                        limit,
                        tagId: filter
                    },
                    fetchPolicy: 'network-only'
                })

                return result.data.users || []
            }
        }
    }

    async oiHandleGetChats(request: Api.GetChatsParams): Promise<Api.Chats> {
        // обрабатываем только запросы на чаты из main списка
        if (request.chatList?._ === 'chatListArchive') {
            return {
                _: 'chats',
                chatIds: [],
                totalCount: 0
            }
        }

        try {
            const filter = request.chatList?._ === 'chatListFilter' ? request.chatList.chatFilterId : undefined

            if (filter !== this.currentFilter) {
                // фильтр изменился, список чатов сброшен, надо очистить список видимых чатов
                this.visibleChats.clear()
                this.currentFilter = filter
                this.offsetChatId = 0
            }
            // Запрашиваем диалоги из нашего API
            //const offsetChatId = 0; //request.chatList ? request.chatList.offsetChatId : 0
            const chatsResult = await this.oiGetChats(this.offsetChatId, request.limit, filter)
            if (!chatsResult.length) {
                return {
                    _: 'chats',
                    chatIds: [],
                    totalCount: 0
                };
            }
            this.offsetChatId = Number(chatsResult[chatsResult.length - 1].user_id);
            console.log('offsetChatId', this.offsetChatId)

            // оставляем только те чаты, которых еще нет в клиенте
            // нельзя случайно передать айди чата повторно
            const chats = chatsResult.filter(c => !this.visibleChats.has(Number(c.user_id)))

            // сообщения в том виде, в котором их получает бот отличаются от
            // сообщений, которые отдает телега клиенту
            // поэтому нужно запросить оригинальные сообщения из админского чата

            // ТГ не позволяет просто открыть чат по айди, если его нет в кэше tdweb
            await this.oiOpenSupportChat()

            // Запрашиваем сообщения из админского чата
            // чтобы использовать из как последние сообщения в списке диалогов
            // const getMessagesResult = toObject(await this.client.api.getMessage())

            await Promise.all(chats.map(async (chat) => {
                if (this.chatScope.has(Number(chat.user_id))) return

                // нужно убедиться, что клиент получил все необходимые уведомления об этом чате
                await this.oiEnsureChat(Number(chat.user_id), chat, filter)
                // const chatLastMessageUpdate = this.oiUpdateChatLastMessage(message)
                // console.log('GET CHATS REQUEST LAST MESSAGE UPDATE', request.chatList?._)
                // this.oiSendUpdate(chatLastMessageUpdate)
            }))

            return {
                _: 'chats',
                chatIds: chats.map((chat) => Number(chat.user_id)),
                totalCount: chats.length || 0
            };
        } catch (e) {
            alert('Всё сломалось, обновите страницу');
            console.error(e);
        }
        return {
            _: 'chats',
            chatIds: [],
            totalCount: 0
        }
    }

    async oiGetChat(userId: number): Promise<UserChatFragment | undefined> {
        const result = await this.oiClient.query<ChatQuery, ChatQueryVariables>({
            query: CHAT_QUERY,
            variables: {
                id: BigInt(userId)
            },
            fetchPolicy: 'network-only'
        })

        return result?.data.users[0]
    }

    /**
     * Отправляет в клиент информацию о новом чате на основе информации от API овд-инфо
     */
    oiUpdateNewChat(oiChat: UserChatFragment, photo?: Api.ChatPhotoInfo) {
        const chat = this.oiCreateChat(Number(oiChat.user_id))
        chat.title = this.oiGetChatTitle(oiChat.first_name || '', oiChat.last_name || '', oiChat.username || '')
        if (photo) {
            chat.photo = photo
        }

        const newChatUpdate: Api.UpdateNewChat = {
            _: 'updateNewChat',
            chat
        }
        return newChatUpdate
    }

    oiGetBasicGroup(userId: number, membersCount = 2): Api.BasicGroup {
        return {
            _: 'basicGroup',
            id: userId,
            memberCount: membersCount,
            status: {
                _: 'chatMemberStatusMember'
            },
            isActive: true,
            upgradedToSupergroupId: 0
        }
    }

    oiCreateChat(userId: number): Api.Chat {
        return {
            //@ts-ignore
            _: 'chat',
            id: userId,
            type: {
                _: 'chatTypeBasicGroup',
                basicGroupId: userId,
                // всё равно передаём user id, чтобы по нему найти телефон
                //@ts-ignore
                userId: userId,
            },
            status: {
                _: 'chatMemberStatusMember'
            },
            title: '',
            permissions: {
                _: 'chatPermissions',
                canSendMessages: true,
                canSendMediaMessages: true,
                canSendPolls: true,
                canSendOtherMessages: true,
                canAddWebPagePreviews: true,
                canChangeInfo: false,
                canInviteUsers: false,
                canPinMessages: false
            },
            positions: [],
            isMarkedAsUnread: false,
            hasScheduledMessages: false,
            canBeDeletedOnlyForSelf: false,
            canBeDeletedForAllUsers: false,
            canBeReported: false,
            defaultDisableNotification: false,
            unreadCount: 0,
            lastReadInboxMessageId: 0,
            lastReadOutboxMessageId: 0,
            unreadMentionCount: 0,
            notificationSettings: {
                _: 'chatNotificationSettings',
                useDefaultMuteFor: true,
                muteFor: 0,
                useDefaultSound: true,
                sound: 'default',
                useDefaultShowPreview: true,
                showPreview: true,
                useDefaultDisablePinnedMessageNotifications: true,
                disablePinnedMessageNotifications: false,
                useDefaultDisableMentionNotifications: true,
                disableMentionNotifications: false
            },
            isBlocked: false,
            replyMarkupMessageId: 0,
            clientData: ""
        }
    }

    /**
     * Создает updateChatLastMessage
     */
    oiUpdateChatLastMessage(message: Api.Message): Api.UpdateChatLastMessage {
        const chatLastMessageUpdate: Api.UpdateChatLastMessage = {
            _: 'updateChatLastMessage',
            chatId: message.chatId,
            lastMessage: message,
            positions: this.oiGetPositions(message.id)
        }
        return chatLastMessageUpdate
    }

    oiGetMessageForwardInfo(message: OiMessageFragment): Api.Message['forwardInfo'] {
        if (!message?.mess?.forward_date) return

        let title

        if (message.mess.forward_signature) {
            title = message.mess.forward_signature
        } else {
            const names = []

            if (message.mess?.forward_from?.first_name) {
                names.push(message.mess.forward_from.first_name)
            }

            if (message.mess?.forward_from?.last_name) {
                names.push(message.mess.forward_from.last_name)
            }

            if (message.mess?.forward_from?.username) {
                names.push(`(${[
                    `@${message.mess?.forward_from?.username}`,
                    `id:${message.mess?.forward_from?.id}`
                ].filter(f => f).join(', ')})`)
            }

            title = names.join(' ')
        }

        return {
            _: 'messageForwardInfo',
            date: message.mess.forward_date,
            origin: {
                _: 'messageForwardOriginHiddenUser',
                senderName: title,
            },
            fromChatId: -1,
            fromMessageId: -1,
            publicServiceAnnouncementType: ''
        }
    }

    /**
     * Преобразует сообщение из админского чата в личное сообщение с пользователем
     * возвращает false, если сообщение надо проигнорировать
     * @param message
     * @param chatId id чата, если известно заранее, чтобы не искать
     */
    async oiModifyMessage(message: Api.Message, chatId?: number, oiMessage?: OiMessageFragment): Promise<boolean> {
        // если id чата известно, ставим его сразу
        if (chatId) {
            message.chatId = chatId
        }

        const replyToMessageId = message.replyToMessageId
        const forwardInfo = message.forwardInfo

        //@ts-ignore
        message.replyInChatId = undefined

        //@ts-ignore
        message.messageThreadId = undefined

        //@ts-ignore
        message.replyToMessageId = undefined

        //@ts-ignore
        message.forwardInfo = undefined

        //@ts-ignore
        message.isOutgoing = message.chatId !== message.senderId?.userId

        //@ts-ignore
        const text: string = message.content?.text?.text
        const parsedChatId = parseId(text)

        if (parsedChatId) {
            message.chatId = parsedChatId
            const lines = text.split('\n')
            lines.pop()
            //@ts-ignore
            message.content.text.text = lines.join('\n')
            return true
        }

        // ответы агента поддержки на сообщения бота
        if (replyToMessageId) {
            // получаем сообщение, на которое ответил агент
            const originalMessage = await this.oiTgGetMessage(Math.floor(replyToMessageId / MAGIC_NUMBER))

            if (!originalMessage) {
                return false
            }

            if (originalMessage.senderId._ === 'messageSenderUser' && originalMessage.senderId.userId !== this.botId) {
                // это не ответ боту, это болтовня в чате, игнорируем
                return false
            }

            message.isOutgoing = true

            if (!chatId && originalMessage.forwardInfo) {
                if (originalMessage.forwardInfo?.origin?._ === 'messageForwardOriginUser') {
                    chatId = originalMessage.forwardInfo.origin.senderUserId
                } else if (originalMessage.forwardInfo.origin._ === 'messageForwardOriginHiddenUser') {
                    // скрытый пользователь, просто так айди узнать не получится
                    // придется делать запрос к API
                    // но нам апдейт может прийти быстрее, чем бэкенду, поэтому подождем три секунды на всякий
                    await wait(3000)
                    const messageInfo = await this.oiGetMessage(Math.floor(originalMessage.id / MAGIC_NUMBER))
                    if (messageInfo) {
                        chatId = Number(messageInfo.user_id) || 0
                    } else {
                        // сообщения нет в базе овд инфо
                        return false
                    }
                }
            }

            if (!chatId) {
                // TODO: делать несколько запросов на получение сообщения, перед тем как сдаться
                console.warn('failed to determine chat id')
                return false
            }

            message.chatId = chatId
            if (oiMessage?.mess) {
                message.forwardInfo = this.oiGetMessageForwardInfo(oiMessage)
            }

            return true
        }

        // сообщения от пользователей
        if (forwardInfo && message.senderId._ === 'messageSenderUser') {
            if (message.senderId.userId !== this.botId) {
                // если это не сообщение от бота, то игнорируем болтовню
                return false
            }
            message.isOutgoing = false
            if (!chatId) {
                if (forwardInfo?.origin._ === 'messageForwardOriginUser') {
                    chatId = forwardInfo.origin.senderUserId
                } else if (forwardInfo.origin._ === 'messageForwardOriginHiddenUser') {
                    // скрытый пользователь, просто так айди узнать не получится
                    // придется делать запрос к API
                    // но нам апдейт может прийти быстрее, чем бэкенду, поэтому подождем три секунды на всякий
                    await wait(3000)
                    const messageInfo = await this.oiGetMessage(Math.floor(message.id / MAGIC_NUMBER))
                    if (messageInfo) {
                        chatId = Number(messageInfo.user_id) || 0
                    } else {
                        // сообщения нет в базе овд инфо
                        return false
                    }
                }
            }

            if (!chatId) {
                console.warn('failed to determine chat id')
                return false
            }

            message.chatId = chatId
            message.senderId = {
                _: 'messageSenderUser',
                userId: chatId
            }
            if (oiMessage?.mess) {
                message.forwardInfo = this.oiGetMessageForwardInfo(oiMessage)
            }

            return true
        }
        return false
    }

    async oiClientOpenChat(chatId: number) {
        await this.oiEnsureChat(chatId)
        this.controller.clientUpdate({
            '@type': 'clientUpdateOpenChat',
            chatId,
            messageId: 0,
            popup: false,
            options: null
        })
        // openChat(chatId)
    }

    /**
     * Основной шлюз, через который сообщения попадают в клиент
     */
    async oiHandleNewMessage(message: Api.Message, knownChatId?: number) {
        if (!await this.oiModifyMessage(message, knownChatId)) return

        // надо убедиться, что чат есть в клиенте
        if (!await this.oiEnsureChat(message.chatId)) return

        // создаем и передаем в клиент уведомление о новом сообщении
        const updateNewMessage: Api.UpdateNewMessage = {
            _: 'updateNewMessage',
            message
        }
        this.oiSendUpdate(updateNewMessage)

        // создаем и передаем в клиент уведомление о новом сообщении в чате
        const chatLastMessageUpdate = this.oiUpdateChatLastMessage(message)
        this.oiSendUpdate(chatLastMessageUpdate)

        // создаем и передаем в клиент отвечено ли сообщение в чате
        const chatIsMarkedAsUnreadUpdate: Api.UpdateChatIsMarkedAsUnread = {
            _: 'updateChatIsMarkedAsUnread',
            chatId: message.chatId,
            isMarkedAsUnread: !message.isOutgoing
        }
        this.oiSendUpdate(chatIsMarkedAsUnreadUpdate)

        // убираем или добавляем чат в список непрочитанных
        const unreadChatPosition = this.oiGetPosition(message.id, FILTER_UNREAD)

        if (message.isOutgoing) {
            unreadChatPosition.order = '0'
        } else {

        }

        const unreadChatPositionUpdate: Api.UpdateChatPosition = {
            _: 'updateChatPosition',
            chatId: message.chatId,
            position: unreadChatPosition
        }
        this.oiSendUpdate(unreadChatPositionUpdate)

        return
    }

    oiChatIsUnread(inbox?: number | null, last?: number | null, banned?: boolean | null): boolean {
        if (banned) {
            return false
        }

        return inbox === last
    }

    async oiGetChatAttempts(chatId: number, attempts: number): Promise<UserChatFragment | undefined> {
        for (let i = 0; i < attempts; i++) {
            console.log('attempt', i, chatId)
            const chat = await this.oiGetChat(chatId)

            if (chat && chat.to_reply_message_id) return chat

            await wait(1000)
        }
    }

    /**
     * Проверяет, есть ли чат в клиенте, и если нет, то запрашивает и добавляет
     */
    async oiEnsureChat(chatId: number, chat?: UserChatFragment, filterId?: number): Promise<boolean> {

        this.visibleChats.add(chatId)

        if (this.chatScope.has(chatId)) return true

        if (!chat) {
            // чат мог не успеть попасть в базу
            chat = await this.oiGetChatAttempts(chatId, 10)
            if (!chat) {
                console.warn('Failed to provide chat info', chatId)
                return false
            }
        }

        if (!this.chatScope.has(chatId)) {
            this.chatScope.set(chatId, chat)
            await this.oiHandleNewChat(chat, filterId)
        }
        return true
    }

    async oiGetUser(userId: number) {
        return getObject(await this.client.api.getUser({
            userId: userId
        }))
    }

    async oiEnsureUser(userId: number, oiChat?: UserChatFragment) {
        if (this.userScope.has(userId)) return
        if (!oiChat) oiChat = await this.oiGetChat(userId)

        let user = getObject(await this.client.api.getUser({
            userId: userId
        }))

        if (!user) {
            user = {
                _: 'user',
                id: userId,
                username: oiChat?.username || '',//this.oiGetChatTitle(oiChat?.first_name, oiChat?.last_name, oiChat?.username),
                firstName: oiChat?.first_name || '',
                lastName: oiChat?.last_name || '',
                phoneNumber: oiChat?.phone_number || '',
                isVerified: false,
                isContact: false,
                isScam: false,
                isFake: false,
                isSupport: false,
                isMutualContact: false,
                haveAccess: true,
                languageCode: 'ru',
                type: {
                    _: 'userTypeRegular'
                },
                restrictionReason: '',
                status: {
                    _: 'userStatusEmpty'
                }
            }
        } else {
            user.phoneNumber = oiChat?.phone_number || user.phoneNumber || ''
        }

        const userUpdate: Api.UpdateUser = {
            _: 'updateUser',
            user
        }

        this.userScope.set(userId, user)
        this.oiSendUpdate(userUpdate)
    }

    async oiHandleNewChat(oiChat: UserChatFragment, filterId?: number) {
        this.chatScope.set(Number(oiChat.user_id), oiChat)

        let message: Api.Message | undefined
        // первым делом ищем сообщение, потому что тогда больше вероятность
        // что в кэше tdlib будет его автор (user)
        if (oiChat.last_message_id) {
            // запрашиваем оригинальное сообщение, с которым может работать клиент
            message = await this.oiTgGetMessage(oiChat.last_message_id)
        }

        this.oiEnsureUser(Number(oiChat.user_id), oiChat)

        // создаем и отправляем уведомление о новом чате
        const newChatUpdate = this.oiUpdateNewChat(oiChat, this.oiGetChatPhotoFromUser(this.userScope.get(Number(oiChat.user_id))))
        this.oiSendUpdate(newChatUpdate)

        const updateBasicGroup: Api.UpdateBasicGroup = {
            _: 'updateBasicGroup',
            basicGroup: this.oiGetBasicGroup(Number(oiChat.user_id), oiChat.operators.length + 1)
        }
        this.oiSendUpdate(updateBasicGroup)

        // создаём и отправляем уведомление о последнем сообщении чата, если оно нашлось
        if (message) {
            await this.oiModifyMessage(message, Number(oiChat.user_id))
            const updateChatLastMessage = this.oiUpdateChatLastMessage(message)
            updateChatLastMessage.positions = []
            this.oiSendUpdate(updateChatLastMessage)
        }

        if (oiChat.last_message_id) {
            const updateChatPosition: Api.UpdateChatPosition = {
                _: 'updateChatPosition',
                chatId: Number(oiChat.user_id),
                position: this.oiGetPosition(oiChat.last_message_id, filterId)
            }
            this.oiSendUpdate(updateChatPosition)
        }

        // создаем и отправляем уведомление отвечен ли этот чат
        const chatIsMarkedAsUnreadUpdate: Api.UpdateChatIsMarkedAsUnread = {
            _: 'updateChatIsMarkedAsUnread',
            chatId: Number(oiChat.user_id),
            isMarkedAsUnread: !!oiChat.unread//this.oiChatIsUnread(oiChat.read_inbox_max_id, oiChat.last_message_id, oiChat.banned_us)
        }
        this.oiSendUpdate(chatIsMarkedAsUnreadUpdate)

    }

    // captureIncomingMessage = (message) => new Promise((res) => {
    //     const handler = (messageUpdate: Api.UpdateNewMessage) => {
    // if(messageUpdate.message.id === )
    //         this.events.off('updateNewMessage', handler)
    //     }
    //     this.events.on('updateNewMessage', handler)
    // })

    /**
     * Отправляет сообщение
     *
     * После отправки сообщения апдейты должны приходить строго в таком порядке:
     * 1. updateNewMessage temp_id
     * 2. updateChatLastMessage temp_id
     * 3. updateMessageSendSucceeded/updateMessageSendFailed old_message_id = temp_id, real_id
     * 4. updateChatLastMessage real_id
     * 5. updateChatReadInbox real_id
     *
     * Чтобы сообщение попало в клиент, оно должно быть приведено в вид, как будто его прислал юзер, а не бот
     * Это делается в методе oiModifyMessage
     * Основная проблема в том, что есть скрытые юзеры, для получения id которых приходится делать доп запрос в наше апи
     * При этом надо ещё и убедиться, что наше апи успело обработать этого юзера, это создаёт большой интервал ожидания
     * и порядок апдейтов сбивается, клиент взрывается, кровь, кишки, и всё такое
     *
     * Но этого можно избежать. При отправке сообщения мы знаем id юзера заранее. Это делает обработку сообщения мнгновенной
     * Поэтому нужно перехватить сообщение, и передать его в обработчик вместе с id юзера
     *
     * Но и тут проблема. При отправке сообщения updateNewMessage приходит раньше, чем происходит await запроса на отправку
     * А мы не знаем id сообщения, которое надо перехватить, пока не получим ответ от метода отправки
     *
     * Поэтому я запихиваю Promise отправки сообщения в массив pendingSendRequests, который проверяю в обработчике updateNewMessage
     * Если есть промисы, надо дождаться их выполнения, а потом уже обрабатывать сообщения
     * Это восстанавливает порядок
    */
    async oiSendMessage(sendRequest: Api.SendMessageParams) {
        if (sendRequest.chatId) {
            const chat = await this.oiGetChatAttempts(sendRequest.chatId, 10)

            if (!chat || !chat.to_reply_message_id) {
                prompt(`
                Сообщение для ответа юзеру не найдено.
                Ответ не может быть отправлен.

                Пожалуйста сообщите информацию ниже разработчикам:
            `, `{ chatId: ${Math.floor(sendRequest.chatId)} }`)
                return
            }

            this.chatScope.set(Number(chat.user_id), chat)

            if (chat) {
                const clientChatId = sendRequest.chatId

                console.log(chat)

                // нужно обязательно убедиться, что сообщение, на которое мы отвечаем есть в кэше tdlib
                // getMessage оффлайновый запрос и ничего не гарантирует
                const messages = toObject(await this.client.api.getMessages({
                    chatId: this.chatId,
                    messageIds: [chat.to_reply_message_id * MAGIC_NUMBER]
                }))

                // const replyToMessage = await this.oiTgGetMessage(chat.to_reply_message_id * MAGIC_NUMBER)

                if (!messages.messages?.[0]) {
                    if (sendRequest?.inputMessageContent?._ === 'inputMessageText') {
                        if (sendRequest.inputMessageContent.text) {
                            sendRequest.inputMessageContent.text.text += `\n<${clientChatId}>`
                        }
                    } else {
                        prompt(
                            `
                            Сообщение для ответа юзеру не найдено.
                            Ответ не может быть отправлен.

                            Пожалуйста сообщите информацию ниже разработчикам:
                            `,
                            `{chatId: ${clientChatId}, to_reply_message_id: ${chat.to_reply_message_id}}`
                        )
                    }
                }

                sendRequest.replyToMessageId = chat.to_reply_message_id * MAGIC_NUMBER
                sendRequest.chatId = this.chatId

                // проблема в том, что от телеги updateNewMessage приходит раньше, чем резолвится промис
                let sendPromise = this.client.api.sendMessage(sendRequest)
                // чтобы её решить, придется сохранить промис, и ждать его разрешения в обработчике updateNewMessage
                this.pendingSendRequests.push(sendPromise)
                let tempMessage = toObject(await sendPromise)

                // 1. updateNewMessage temp_id
                // 2. updateChatLastMessage temp_id
                this.oiHandleNewMessage(tempMessage, clientChatId)

                // 3. updateMessageSendSucceeded/updateMessageSendFailed old_message_id = temp_id, real_id
                const messageSentUpdate = await this.oiWaitForMessageSendingState(tempMessage.id)

                await this.oiModifyMessage(messageSentUpdate.message, clientChatId)

                this.oiSendUpdate(messageSentUpdate)

                // 4. updateChatLastMessage real_id
                const updateChatLastMessage = this.oiUpdateChatLastMessage(messageSentUpdate.message)
                this.oiSendUpdate(updateChatLastMessage)

                return tempMessage
            }
        }
    }

    /**
     * После отправки сообщения телега пришлёт уведомление, было ли оно успешно или нет
     * Ждем это уведомление и возвращаем
     */
    oiWaitForMessageSendingState(messageId: number): Promise<Api.UpdateMessageSendSucceeded | Api.UpdateMessageSendFailed> {
        return new Promise((res, rej) => {
            const handleSuccess = ({ update }: UpdateContext<Api.UpdateMessageSendSucceeded>) => {
                if (messageId === update.oldMessageId && update.message.chatId === this.chatId) {
                    this.events.off('updateMessageSendSucceeded', handleSuccess)
                    this.events.off('updateMessageSendFailed', handleFailed)

                    res(update)
                }
            }
            const handleFailed = ({ update }: UpdateContext<Api.UpdateMessageSendFailed>) => {
                if (messageId === update.oldMessageId && update.message.chatId === this.chatId) {
                    this.events.off('updateMessageSendSucceeded', handleSuccess)
                    this.events.off('updateMessageSendFailed', handleFailed)

                    res(update)
                }
            }
            // подписываемся на оба этих события и при получении одного из них отписываемся от обоих
            this.events.on('updateMessageSendSucceeded', handleSuccess)
            this.events.on('updateMessageSendFailed', handleFailed)
        })
    }

    oiGetChatPhotoFromUser(user?: Api.User): Api.ChatPhotoInfo | undefined {
        if (user && user.profilePhoto) {
            const photo: Api.ChatPhotoInfo = {
                _: 'chatPhotoInfo',
                hasAnimation: false,
                small: user.profilePhoto.small,
                big: user.profilePhoto.big
            }
            return photo
        }
    }

    async oiGetMessages(chatId: bigint, offsetMessageId = 0, limit = 50) {
        const result = await this.oiClient.query<MessagesQuery, MessagesQueryVariables>({
            query: MESSAGES_QUERY,
            variables: {
                chatId,
                offsetMessageId: offsetMessageId || null,
                limit
            },
            fetchPolicy: 'network-only'
        })

        return result.data.messages || []
    }

    /**
     * Получение списка сообщений с конкретным юзером от ОВД-инфо
     */
    async oiHandleGetChatHistory(request: Api.GetChatHistoryParams): Promise<Api.Messages> {
        const emptyMessages: Api.Messages = {
            _: 'messages',
            messages: [],
            totalCount: 0
        }

        if (!request.chatId) {
            return emptyMessages
        }

        // Запрашиваем сообщения из нашего API
        const oiMessages = await this.oiGetMessages(BigInt(request.chatId), Math.floor((request.fromMessageId || 0) / MAGIC_NUMBER), request.limit)
        console.log(oiMessages)

        if (!oiMessages.length) {
            return emptyMessages
        }

        // переводим Id сообщений в понятный для tdweb вид
        const messageIds = oiMessages.map((m) => (m.message_id || 0) * MAGIC_NUMBER);

        await this.oiOpenSupportChat()

        // получаем те же сообщения, но от ТГ в правильном виде
        const messagesData = await this.client.api.getMessages({
            chatId: this.chatId,
            messageIds
        })

        if (messagesData.response._ === 'messages') {
            const messagesResponse = messagesData.response

            if (!messagesResponse.messages) {
                return messagesResponse
            }

            // некоторые сообщения могут быть null
            const messages = messagesResponse.messages.map((m, i) => {
                if (!m) {
                    const backupMessage = this.oiGetBackupMessage(oiMessages[i])
                    // backupMessage.date = oiMessages[i].date
                    return backupMessage
                }
                return m
            })

            //@ts-ignore

            const result = await Promise.all(messages.map((m, i) => this.oiModifyMessage(m, request.chatId, oiMessages[i])))

            // await Promise.all(result.map(message => this.oiEnsureUser()))
            return {
                _: 'messages',
                messages,
                totalCount: messages.length
            }
        }

        return emptyMessages
    }

    /**
     * пытаемся вернуть сообщение в том виде, в котором оно приходит от телеги
     */
    oiGetBackupMessage(m: MessageFragment): Api.Message {
        const message: BotMessage = m.mess

        const entities: Api.TextEntity[] = message.entities?.map(e => ({
            _: 'textEntity',
            ...e,
            type: e.type as unknown as Api.TextEntityTypeUnion
        })) || []

        return {
            _: 'message',
            id: (m.message_id || 0) * MAGIC_NUMBER,
            isOutgoing: message.chat.id !== message.from?.id,
            chatId: this.chatId,
            isPinned: false,
            senderId: {
                _: 'messageSenderUser',
                userId: message.from?.id || 0
            },
            canBeEdited: false,
            canBeForwarded: false,
            canBeDeletedForAllUsers: false,
            canBeDeletedOnlyForSelf: false,
            canGetMessageThread: false,
            canGetStatistics: false,
            canBeSaved: false,
            canGetViewers: false,
            canGetMediaTimestampLinks: false,
            hasTimestampedMedia: false,
            isChannelPost: false,
            containsUnreadMention: false,
            date: message.date,
            editDate: message.date,
            replyInChatId: 0,
            replyToMessageId: 0,
            messageThreadId: 0,
            ttl: 0,
            ttlExpiresIn: 0,
            viaBotUserId: 0,
            authorSignature: '',
            mediaAlbumId: '',
            restrictionReason: '',
            content: {
                _: 'messageText',
                text: {
                    _: 'formattedText',
                    text: `${message.text}`,
                    entities
                }
            }
        }
    }

    oiGetServiceMessage(messageId: number, chatId: number): Api.Message {
        return {
            _: 'message',
            id: messageId,
            isOutgoing: false,
            chatId,
            isPinned: false,
            senderId: {
                _: 'messageSenderUser',
                userId: 0
            },
            canBeEdited: false,
            canBeForwarded: false,
            canBeDeletedForAllUsers: false,
            canBeDeletedOnlyForSelf: false,
            canGetMessageThread: false,
            canGetStatistics: false,
            canBeSaved: false,
            canGetViewers: false,
            canGetMediaTimestampLinks: false,
            hasTimestampedMedia: false,
            isChannelPost: false,
            containsUnreadMention: false,
            date: 0,
            editDate: 0,
            replyInChatId: 0,
            replyToMessageId: 0,
            messageThreadId: 0,
            ttl: 0,
            ttlExpiresIn: 0,
            viaBotUserId: 0,
            authorSignature: '',
            mediaAlbumId: '0',
            restrictionReason: '',
            content: {
                _: 'messageCustomServiceAction',
                text: `сообщение #${messageId} не найдено в чате бота`
            }
        }
    }

    oiGetChatFilterInfo(id: number, name: string, iconName?: Api.ChatFilterInfo['iconName']): Api.ChatFilterInfo {
        return {
            _: 'chatFilterInfo',
            id,
            iconName: iconName || '',
            title: name
        }
    }

    /**
     * Убеждается, что чат есть в кэше tdlib
     */
    async findChat(chatId: number): Promise<boolean> {
        // Этот метод работает очень странно, и наглухо зависает без ошибок,
        // если передать неправильные параметры

        // У меня не получилось добиться от него того, чтобы он нормально
        // реагировал на offsetOrder
        const result = await this.client.api.getChats({
            chatList: {
                _: 'chatListMain'
            },
            limit: 50/*,
            offsetChatId: 0,
            offsetOrder: ORDER*/
        })
        //@ts-ignore
        const chats = toObject(result)
        //@ts-ignore
        const chatIds = chats.chatIds
        if (chatIds.length === 0) {
            return false
        }
        if (chatIds.includes(chatId)) {
            return true
        }

        return false
    }

    /**
     * возвращает странный объект, отвечающий за порядок чатов
     * @argument messageId длинный id сообщения
     */
    oiGetPositions(messageId: number): Api.ChatPosition[] {
        return ([
            this.oiGetPosition(messageId)
        ])
    }

    oiGetPosition(messageId: number, filterId?: number): Api.ChatPosition {
        if (filterId) {
            return {
                _: 'chatPosition',
                order: (messageId).toString(),
                isPinned: false,
                list: {
                    _: 'chatListFilter',
                    chatFilterId: filterId
                }
            }
        }

        return {
            _: 'chatPosition',
            order: (messageId).toString(),
            isPinned: false,
            list: {
                _: 'chatListMain'
            }
        }
    }

    oiGetChatTitle(firstName?: string, lastName?: string, username?: string): string {
        let title = ''

        if (firstName) {
            title += firstName
            if (lastName) {
                title += ' ' + lastName
            }
        } else {
            title += username
        }

        return title
    }

    async oiOpenSupportChat() {
        if (this.currentTdlibChatId === this.chatId) return
        await this.findChat(this.chatId)

        // убеждаемся, что телега закешировала админский чат
        const onServer = await this.client.api.searchChatsOnServer({
            query: this.chatName
        })

        const local = await this.client.api.searchChats({
            query: this.chatName
        })

        const searchContacts = await this.client.api.searchContacts({
            query: this.chatName
        })

        // открываем админский чат для запроса сообщений
        await this.client.api.openChat({
            chatId: this.chatId
        }).catch(e => {
            alert('Чат ' + this.chatName + ' не найден в списке чатов. Убедитесь, что вы есть в этом чате')
        })
        this.currentTdlibChatId = this.chatId
    }
}
const oiBotClient = new OiBotClient()
export default oiBotClient

export {
    AUTH_STORAGE_KEY,
    USER_ID_STORAGE_KEY
}
