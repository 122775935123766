import { gql } from '@apollo/client'

export default gql`
    query Tags {
        tags {
            tag_id
            tag_name
        }
    }
`

